<template>
  <allowed-denied-sidebar
    :uuid="uuid"
    :middlewareID="middlewareID"
    :middlewareType="middlewareType"
    v-model="agents"
    i18nKey="denied_list"
    @saved="savedAgents"
    @close="$emit('close')"
  />
</template>

<script>
import AllowedDeniedSidebar from '@/views/pages/middleware/MiddlewareManagement/Components/MiddlewareSidebar/AllowedDeniedSidebar.vue'
import Middlewares from "@/custom/class/Enum/Middlewares.js"

  export default {
    components: {
      AllowedDeniedSidebar
    },
    props: {
      middlewareID: {
        type: Number,
        required: true,
      },
      uuid: {
        type: String,
        required: true,
      },
      value: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        middlewareType: new Middlewares().items.find(el=> el.id == 4),
      }
    },
    computed: {
      agents: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value)
        }
      }
    },
    methods: {
      savedAgents(payload) {
        this.$emit('saved', payload)
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>